.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 40px 20px 50px;
  background-color: var(--light-bg-color);
  scroll-margin-top: 50px;
}

.hero_content {
  max-width: 55%;
  text-align: left;
}

.hero h1 {
  font-size: 2.5rem;
  color: var(--text-color);
}

.hero p {
  font-size: 1rem;
  color: #555;
  margin: 20px 0;
  line-height: 1.6;
}

.get_started_button {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  width: fit-content;
  margin-top: 20px;
}

.hero_image {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  margin-block: 20px;
}

.hero_image img {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
}

.hero_image_text {
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  left: -40px;
  background: rgba(224, 224, 224, 0.85);
  padding: 10px;
  border-radius: 5px;
  max-width: 65%;
}

.hero_image_text .icon {
  color: var(--secondary-color);
  margin-right: 10px;
  width: 40px;
}

.hero_image_text p {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .hero {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px; 
  }

  .hero_content {
    max-width: 100%;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 0.95rem;
  }

  .hero_image {
    width: 100%;
    margin-block: 30px;
  }

  .hero_image img {
    max-width: 90%; 
  }

  .hero_image_text {
    left: 10px;
    max-width: 90%;
  }
}
