.container {
  margin: 0 auto;
  max-width: 1200px;
}

.contact_us {
  padding: 100px 20px;
  background-color: #f8f8f8;
  width: 80%;
  font-size: 20px;
}

.contact_us .block {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.contact_us .icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.contact_us .mail, .contact_us .phone, .contact_us .address {
  font-weight: 700;
  color: #333;
}

.contact_us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.contact_us > p {
  text-align: center;
  color: #333;
}

.director {
  text-align: center;
}

@media (max-width: 769px) {
  .container {
    width: 100%;
  }

  .contact_us {
    padding: 0 20px;
    margin: 40px 0;
    font-size: small;
    scroll-margin-top: 70px;
  }

  .contact_us h2 {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }

  .contact_us .icon {
    width: 28px;
    height: 28px;
  }

  .contact_us .phone, .contact_us .mail, .contact_us .address {
    font-size: 0.9rem;
  }
}
