.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

.services {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2d3e50;
}

.service_cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.service_card {
  background-color: var(--light-bg-color);
  padding: 30px 20px; 
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service_card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service_card h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 15px;
}

.service_card .icon {
  font-size: 5rem;
  color: #21867a;
  margin-bottom: 15px;
}

.service_card ul {
  font-size: 0.9rem;
  text-align: left;
  list-style: disc;
  margin-block: 10px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .container {
      width: 100%;
  }

  .service_cards {
      flex-direction: column;
      align-items: center;
  }

  .service_card {
      max-width: 90%;
      margin: 10px 0;
  }

  .services h2 {
      font-size: 1.8rem;
  }

  .service_card ul {
      font-size: 0.85rem;
  }

  .service_card .icon {
      font-size: 4rem;
  }
}
